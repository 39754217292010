<template>
  <v-card elevation="6" v-if="customer" id="customer-card">
    <v-card-title class="py-3">
      <h2
        style="text-transform: uppercase; font-weight: normal"
        v-text="$tc('customer')"
      ></h2>
      <h5 v-if="extra_customers && extra_customers.length > 1">
        {{
          $tc("extra_customers.count", extra_customers.length, {
            count: extra_customers.length,
          })
        }}
      </h5>
      <AddExtraCustomer
        v-if="$store.getters['auth/isLoggedIn'] && appointment"
        :absolute="true"
        :appointment="appointment"
        :edit="edit"
        @update="$emit('update')"
        :first="extra_list.length === 0"
      />
      <v-btn
        icon
        v-if="
          $store.getters['auth/isLoggedIn'] &&
            $route.name === 'appointmentView' &&
            appointment &&
            appointment.status !== 'creating'
        "
        style="position: absolute; right: 60px"
        :disabled="edit == false || extra_list.length > 1"
        small
        @click="$emit('asignate', customer)"
      >
        <v-icon color="primary"> mdi-repeat-variant </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="
          $router.push({ name: 'customerEdit', params: { id: customer.id } })
        "
        v-if="
          $store.getters['auth/isLoggedIn'] &&
            ((appointment && appointment.status !== 'creating') ||
              !appointment) &&
            extra_list.length !== 0
        "
        :disabled="edit == false"
        style="position: absolute; right: 25px"
        small
      >
        <v-icon small color="primary"> $edit </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider> </v-divider>
    <template v-for="(c, i) in extra_list">
      <v-simple-table :key="c.id">
        <tr>
          <td>{{ $t("first_name") }}</td>
          <td>{{ c.user.first_name }} {{ c.user.last_name }}</td>
        </tr>
        <tr>
          <td>{{ $t("consent-form.email") }}</td>
          <td>{{ c.user.email || "-" }}</td>
        </tr>
        <tr>
          <td>{{ $t("phone") }}</td>
          <td>
            {{ c.user.phone || "-" }}
            <WhatsApp :phone_number="c.user.phone" />
          </td>
        </tr>

        <tr v-if="$store.getters['auth/isLoggedIn']">
          <td style="vertical-align: baseline">
            {{ $t("appointments.description") }}
          </td>
          <td>
            <v-textarea
              v-bind:class="[{ disabled: !edit_list[i] }]"
              outlined
              v-model="c.description"
              hide-details
              :rows="c.description && c.description.length > 1000 ? 3 : 2"
              style="border-radius: 10px"
              :disabled="edit == false"
              @blur="update(c, i)"
              @click="editDescription(i)"
            ></v-textarea>
          </td>
        </tr>
        <template v-if="show[i]">
          <tr>
            <td>{{ $t("studios.birthdate") }}</td>
            <td>
              {{ c.birthdate ? $d(new Date(c.birthdate), "dateShort") : "-" }}
              <span v-if="menor">
                <td style="color:red" v-if="$vuetify.breakpoint.mdAndUp">
                  {{ $t("under.age") }}
                </td>
                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="red" dark v-bind="attrs" v-on="on">
                      $underage
                    </v-icon>
                  </template>
                  <span>{{ $t("under.age") }}</span>
                </v-tooltip>
              </span>
            </td>
          </tr>
          <tr>
            <td>{{ $t("customers.city") }}</td>
            <td>
              {{ c.city || "-" }}
            </td>
          </tr>
          <tr>
            <td>{{ $t("customers.height") }}</td>
            <td>{{ c.height ? $n(c.height) + " m" : "-" }}</td>
          </tr>
          <tr>
            <td>{{ $t("customers.weight") }}</td>
            <td>{{ c.weight ? $n(c.weight) + " kg" : "-" }}</td>
          </tr>
          <tr>
            <td>{{ $t("customers.allergies") }}</td>
            <td>{{ c.allergies || "No" }}</td>
          </tr>
          <tr v-if="$store.getters['auth/isTattooer']">
            <td>{{ $t("files") }}</td>
            <td>
              <v-btn class="ml-n1" small icon @click="goToFiles">
                <v-icon small>$eye</v-icon>
              </v-btn>
            </td>
          </tr>
          <slot></slot>
        </template>
        <tr>
          <td colspan="2">
            <a @click="change(i)">
              <u>{{ show[i] ? $t("see_less") : $t("see_more") }}</u>
            </a>
            <a
              class="ml-3"
              v-if="extra_list.length > 1 && $store.getters['auth/isLoggedIn']"
              @click="
                $router.push({ name: 'customerEdit', params: { id: c.id } })
              "
            >
              <u>Editar</u>
            </a>
            <a
              class="ml-3"
              v-if="extra_list.length > 1 && $store.getters['auth/isLoggedIn']"
              @click="changeCustomer(c)"
            >
              <u>Cambiar</u>
            </a>
            <a
              class="ml-3"
              v-if="extra_list.length > 1 && $store.getters['auth/isLoggedIn']"
              @click="del(c)"
            >
              <u>{{ $t("delete", { name: "" }) }}</u>
            </a>
          </td>
        </tr>
      </v-simple-table>

      <v-divider :key="`divider-${c.id}`" v-if="i < extra_list.length - 1" />
    </template>
    <v-simple-table v-if="extra_list.length === 0">
      <tr>
        <td class="text-center">
          <h2 style="color: red !important">
            {{ $t("customers.not_assigned") }}
          </h2>
        </td>
      </tr>
    </v-simple-table>
  </v-card>
</template>

<script>
import * as moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "CustomerCard",
  props: ["customer", "studio", "edit", "extra_customers", "appointment"],
  components: {
    ExtraCustomers: () => import("@/components/customer/ExtraCustomers"),
    AddExtraCustomer: () => import("@/components/customer/AddExtraCustomer"),
  },
  methods: {
    ...mapActions("customers", ["updateCustomerWithoutDate"]),
    update(c, i) {
      console.log("update CLIENT");
      //
      this.updateCustomerWithoutDate({
        customer_id: c.id,
        customer: c,
      }).then((response) => {
        this.editDescription(i);
      });
    },
    change(i) {
      let a = [];
      this.extra_list.forEach((element, j) => {
        if (j === i) a.push(!this.show[j]);
        else a.push(this.show[j]);
      });
      this.show = a;
    },

    changeCustomer(c) {
      this.$emit("asignate", c);
    },
    ...mapActions("appointments", ["removeCustomer"]),
    del(c) {
      this.$confirm(this.$t("delete_customer")).then(() => {
        this.removeCustomer({
          appointment_id: this.appointment.id,
          customer_id: c.id,
        }).then(() => {
          this.$emit("update");
        });
      });
    },
    editDescription(i) {
      let e = [];
      this.extra_list.forEach((element, j) => {
        if (j === i) e.push(!this.edit_list[j]);
        else e.push(this.edit_list[j]);
      });
      this.edit_list = e;
    },
    goToFiles() {
      console.log(this.customer);
      console.log(this.studio);
      let name = `${this.customer.user.first_name}`;
      if (this.customer.user.last_name) {
        name += ` ${this.customer.user.last_name}`;
      }
      if (this.studio)
        this.$router.push({
          name: "filesView",
          params: {
            sub_route: `${this.studio}/${name}`,
          },
        });
    },
  },
  data() {
    return { show: [], edit_list: [] };
  },
  watch: {
    extra_list: {
      handler() {
        let a = [];
        let e = [];
        this.extra_list.forEach((element) => {
          a.push(false);
          e.push(false);
        });
        this.show = a;
        this.edit_list = e;
      },
      deep: true,
    },
  },
  mounted() {
    let a = [];
    let e = [];
    this.extra_list.forEach((element) => {
      a.push(false);
      e.push(false);
    });
    this.show = a;
    this.edit_list = e;
  },
  computed: {
    menor() {
      let menor = false;
      let today = moment();
      let birthdate = moment(this.customer.birthdate);
      let edad = today.diff(birthdate, "years");
      console.log(edad);
      if (edad < 18) {
        menor = true;
      }
      return menor;
    },

    extra_list() {
      if (this.extra_customers && this.extra_customers.length)
        return this.extra_customers;
      if (this.customer.user.first_name) return [this.customer];
      return [];
    },
  },
};
</script>

<style scoped lang="sass">
.v-data-table

  td
    padding: 3px 8px !important
    &:first-child
      color: var(--v-gris1-base)
      min-width: 100px
      width: 100px
    &:last-child
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      max-width: 300px
  td:first-child
    color: var(--v-gris1-base)

h2
  font-weight: normal
  font-size: 20px
</style>
<style lang="sass">
#customer-card
  .v-input
    &.disabled
      fieldset
        border-color: rgba(255, 255, 255, 0.5) !important
</style>
